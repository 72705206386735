<template>
    <ActionsPage />
</template>

<script>
import {
    mapActions
} from 'vuex'

import ActionsPage from '../components/ActionsPage.vue'

export default {
    data: () => ({
    }),
    components: {
        ActionsPage
    },
    methods: {
        ...mapActions(['getUserInfo'])
    },
    mounted() {
    }
}
</script>

