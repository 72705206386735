<template>
<div>
    <div class="pb-12">
        <!-- Фильтры -->
        <v-toolbar flat height="70" v-if="collapsed">
            <span class="title primary--text">Фільтр акцій</span>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="$store.commit('openCloseFiltersDrawer', false);">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card outlined width="100%">
            <v-chip class="ma-2" outlined v-if="clearable" @click="clearFilters()">
                <v-icon left small>mdi-filter-remove</v-icon>
                <span class="caption">Очистити фільтри</span>
            </v-chip>
            <v-divider></v-divider>
            <!-- {{filters}} -->

            <!-- Тип акції -->
            <v-list nav dense class="px-1">
                <v-list-group v-model="typeFilter" active-class="grey--text">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="subtitle-2 grey--text text--darken-3">Тип акції</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item @click="loadListR()" v-for="type in typesM" :key="type.id" class="px-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-subtitle class="pr-1">
                                <v-row align="center" class="py-2 px-2">
                                    <v-checkbox v-model="types[type.id]" hide-details class="filter-item shrink my-3 ml-4 pt-0" :disabled="filters.typesFilter && filters.typesFilter[type.id] ? false : true">
                                        <template v-slot:label>
                                            <span class="subtitle-2">{{type.name}}
                                                <span v-if="filters.typesFilter && filters.typesFilter[type.id] && !types[type.id]" class="grey--text pl-1">
                                                    (<span v-if="Object.keys(types).find(el => types[el])">+</span>{{filters.typesFilter && filters.typesFilter[type.id]}})
                                                </span>
                                            </span>
                                        </template>
                                    </v-checkbox>
                                </v-row>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
            <v-divider></v-divider>
            <!-- Статусы -->
            <v-list nav dense class="px-1">
                <v-list-group v-model="statusFilter" active-class="grey--text">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="subtitle-2 grey--text text--darken-3">Статус акції</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item @click="loadListR()" v-for="status in statuses" :key="status.id" class="px-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-subtitle class="pr-1">
                                <v-row align="center" class="py-2 px-2">
                                    <v-checkbox v-model="stats[status.id]" hide-details class="filter-item shrink my-3 ml-4 pt-0" :disabled="filters.statsFilter && filters.statsFilter[status.id] ? false : true">
                                        <template v-slot:label>
                                            <span class="subtitle-2">{{status.name}}
                                                <span v-if="filters.statsFilter && filters.statsFilter[status.id] && !stats[status.id]" class="grey--text pl-1">
                                                    (<span v-if="Object.keys(stats).find(el => stats[el])">+</span>{{filters.statsFilter && filters.statsFilter[status.id]}})
                                                </span>
                                            </span>
                                        </template>
                                    </v-checkbox>
                                </v-row>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
            <v-divider></v-divider>
            <!-- Категории -->
            <v-list nav dense class="px-1">
                <v-list-group v-model="catsFilter" active-class="grey--text">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="subtitle-2 grey--text text--darken-3">Категорія</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item @click="loadListR()" v-for="category in catalogTree().menuItems" :key="category.link" class="px-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-subtitle class="pr-1">
                                <v-row align="center" class="py-2 px-2">
                                    <v-checkbox v-model="cats[category.link]" hide-details class="filter-item shrink my-3 ml-4 pt-0" :disabled="filters.catsFilter && filters.catsFilter[category.link] ? false : true">
                                        <template v-slot:label>
                                            <span class="subtitle-2">{{category.text}}
                                                <span v-if="filters.catsFilter && filters.catsFilter[category.link] && !cats[category.link]" class="grey--text pl-1">
                                                    (<span v-if="Object.keys(cats).find(el => cats[el])">+</span>{{filters.catsFilter && filters.catsFilter[category.link]}})
                                                </span>
                                            </span>
                                        </template>
                                    </v-checkbox>
                                </v-row>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
            <v-divider></v-divider>
            <!-- Проміжні підсумки -->
            <v-list nav dense class="px-1">
                <v-list-group v-model="resultsFilter" active-class="grey--text">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="subtitle-2 grey--text text--darken-3">Проміжні підсумки</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item @click="loadListR()" v-for="res in results" :key="res.id" class="px-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-subtitle class="pr-1">
                                <v-row align="center" class="py-2 px-2">
                                    <v-checkbox v-model="ress[res.id]" hide-details class="filter-item shrink my-3 ml-4 pt-0" :disabled="filters.ressFilter && filters.ressFilter[res.id] ? false : true">
                                        <template v-slot:label>
                                            <span class="subtitle-2">{{res.name}}
                                                <span v-if="filters.ressFilter && filters.ressFilter[res.id] && !ress[res.id]" class="grey--text pl-1">
                                                    (<span v-if="Object.keys(ress).find(el => ress[el])">+</span>{{filters.ressFilter && filters.ressFilter[res.id]}})
                                                </span>
                                            </span>
                                        </template>
                                    </v-checkbox>
                                </v-row>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-card>
    </div>
    <v-bottom-navigation v-model="bottomNav" v-if="collapsed" absolute>
        <v-spacer></v-spacer>
        <v-btn value="recent" @click="$store.commit('openCloseFiltersDrawer', false)">
            <span>Показати</span>
            <v-icon>mdi-filter-plus</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
    </v-bottom-navigation>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters

} from 'vuex'
export default {
    components: {},
    props: {
        collapsed: Boolean
    },
    data() {
        return {
            stats: {
                // current: true
            },
            cats: {},
            ress: {},
            types: {},
            page: 1,
            statuses: [{
                    id: 'work_reg',
                    name: 'Реєстрація'
                },
                {
                    id: 'current',
                    name: 'Актуальна'
                },
                {
                    id: 'archive',
                    name: 'Архив'
                }
            ],
            results: [{
                    id: 'yes',
                    name: 'Так'
                },
                {
                    id: 'no',
                    name: 'Нi'
                }
            ],
            typesM: [{
                    id: 'spec',
                    name: 'Спец. ціна'
                },
                {
                    id: 'gift',
                    name: 'Подарункова'
                },
                {
                    id: 'bonus',
                    name: 'Бонусна'
                },
                {
                    id: 'other',
                    name: 'Інша'
                },
            ],
            statusFilter: true,
            catsFilter: true,
            resultsFilter: true,
            typeFilter: true,
            newQuery: {}
        }
    },
    methods: {
        ...mapActions(['actionsList', 'touch', 'searchGoods', 'getActionsFilters', 'getContactInfo']),
        ...mapGetters(['catalogTree']),
        loadList() {
            this.setQuery()
        },
        setQuery(reset) {
            const stats = (Object.keys(this.stats)).filter(el => this.stats[el])
            const cats = (Object.keys(this.cats)).filter(el => this.cats[el])
            const ress = (Object.keys(this.ress)).filter(el => this.ress[el])
            const types = (Object.keys(this.types)).filter(el => this.types[el])
            delete this.$route.query['cats']
            delete this.$route.query['stats']
            delete this.$route.query['ress']
            delete this.$route.query['types']
            if (reset) {
                delete this.$route.query['page']
            }
            Object.assign(this.newQuery, {
                ...this.$route.query,
                cats: cats.length ? cats.join(',') : undefined,
                stats: stats.length ? stats.join(',') : undefined,
                ress: ress.length ? ress.join(',') : undefined,
                types: types.length ? types.join(',') : undefined,
            })
            this.$router.push({
                path: '',
                query: this.newQuery
            })
            this.newQuery = {}

        },
        loadListR() {
            this.setQuery(true)
        },
        loadGoodsList() {
            this.searchGoods(this.searchGood)
        },
        clearFilters() {
            this.stats = {}
            this.cats = {}
            this.ress = {}
            this.types = {}
            this.loadListR()
        },
        parseQuerySet() {
            const q = this.$route.query
            const cats = q.cats && q.cats.split(',')
            const ress = q.ress && q.ress.split(',')
            const stats = q.stats && q.stats.split(',')
            const types = q.types && q.types.split(',')

            if (cats) {
                for (let id of cats) {
                    this.cats[id] = true
                }
            }
            if (ress) {
                for (let id of ress) {
                    this.ress[id] = true
                }
            }
            if (stats) {
                for (let id of stats) {
                    this.stats[id] = true
                }
            } else {
                this.stats['current'] = true
            }

            if (types) {
                for (let id of types) {
                    this.types[id] = true
                }
            }
        },
        folding() {
            //сворачивание неактивных параметров в режиме телефона
            if (this.collapsed) {
                this.statusFilter = false
                this.catsFilter = false
                this.resultsFilter = false
                this.typeFilter = false
                if (Object.keys(this.stats).find(el => this.stats[el])) {
                    this.statusFilter = true
                }
                if (Object.keys(this.cats).find(el => this.cats[el])) {
                    this.catsFilter = true
                }
                if (Object.keys(this.ress).find(el => this.ress[el])) {
                    this.resultsFilter = true
                }
                if (Object.keys(this.types).find(el => this.types[el])) {
                    this.typeFilter = true
                }
            }
        }
    },
    watch: {},
    created() {
        this.parseQuerySet()
        this.setQuery()
    },
    mounted() {
        this.folding()
    },
    computed: {
        list() {
            return this.$store.state.actions.list
        },
        totalItems() {
            return this.$store.state.actions.total
        },
        listLoading() {
            return this.$store.state.actions.loading
        },
        goodsItems() {
            return this.$store.state.actions.searchGoods
        },
        filters() {
            return this.$store.state.actions.filters
        },
        clearable() {
            return Object.keys(this.cats).filter(el => this.cats[el]).length ||
                Object.keys(this.types).filter(el => this.types[el]).length ||
                Object.keys(this.ress).filter(el => this.ress[el]).length ||
                Object.keys(this.stats).filter(el => this.stats[el]).length ? true : false
        },
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
    },
}
</script>

<style>
.v-text-field.v-text-field--solo.v-input--dense>.v-input__control {
    height: 38px;
}

.filter-item .v-input__slot {
    align-items: start;
}

.filter-item .v-input__slot span {
    white-space: normal;
}
</style>
