<template>
<v-container fluid fill-height v-if="show">
    <h1>Акції</h1>
    <v-card flat tile width="100%" class="mt-2" v-if="$vuetify.breakpoint.smAndDown">
        <v-btn color="primary" block dark @click.stop="drawerOpenClose()">
            Фільтри
            <v-icon right>mdi-filter-menu-outline</v-icon>
        </v-btn>
        <v-navigation-drawer v-model="$store.state.catalog.filtersDrawerOpened" stateless clipped temporary app width="320">
            <ActionsFiltersBlock collapsed />
        </v-navigation-drawer>

    </v-card>
    <v-row no-gutters>
        <v-col xl="2" lg="2" md="3" class="pr-2" v-if="$vuetify.breakpoint.mdAndUp">
            <ActionsFiltersBlock />
        </v-col>
        <v-col xl="10" lg="10" md="9">
            <v-toolbar flat>
                <v-text-field light @keyup.enter="loadListR(true)" class="ml-n4 ml-md-n2 mr-1 mt-md-n7" v-model="actionId" flat outlined solo dense hide-details append-icon="search" label="Шукати за номером">
                </v-text-field>
                <v-spacer></v-spacer>
                <v-autocomplete @change="loadListR(true)" class="mr-n4 mr-md-n2 mt-md-n7" v-model="goods" label="Шукати за товаром" dense flat outlined solo :items="goodsItems" :loading="isLoading" :search-input.sync="searchGood" clearable>
                    <template v-slot:no-data>
                        <span class="ml-4 grey--text font-italic">товари не знайдено</span>
                    </template>
                </v-autocomplete>
            </v-toolbar>
            <div class="body-2 text-right px-0 px-md-2 mt-4">
                Знайдено акцій: {{totalItems}}</div>
            <v-data-iterator :loading="listLoading" :items="list" :footer-props="footerProps" hide-default-footer :page.sync="page" @page-count="pageCount = $event" :server-items-length="totalItems" :options.sync="options">
                <template v-slot:default="props">
                    <v-row no-gutters>
                        <v-col class="pa-0 pa-sm-1 pt-0 pb-2" v-for="item in props.items" :key="item.typhoon_full_id" cols="12" sm="6" md="4" lg="4" xl="3">
                            <!-- ячейка акции -->
                            <ActionsItem :item="item" />
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:loading>
                    <div class="py-10 body-2 grey--text text-center font-weight-light">Зачекайте...</div>
                </template>
                <template v-slot:no-data>
                    <div class="py-10 body-2 grey--text text-center font-weight-light">Немає жодної акції.</div>
                </template>
            </v-data-iterator>
            <div class="text-xs-center pt-2">
                <v-pagination color="primary" v-model="page" :length="pageCount" :total-visible="totalPagesPagination"></v-pagination>
            </div>

        </v-col>
    </v-row>
</v-container>
</template>

<script>
import {
    mapActions,
    mapGetters

} from 'vuex'
import ActionsItem from './ActionsItem.vue'
import ActionsFiltersBlock from './ActionsFiltersBlock.vue'
export default {
    components: {
        ActionsItem,
        ActionsFiltersBlock
    },
    props: {},
    data() {
        return {
            show: false,
            page: 1,
            pageCount: 0,
            totalPagesPagination: 7,
            actionId: '',
            goods: '',
            //goodsItems: [],
            searchGood: null,
            isLoading: false,
            stats: {
                current: true
            },
            cats: {},
            ress: {},
            types: {},
            options: {
                page: 1,
                itemsPerPage: 20
            },
            footerProps: {
                'items-per-page-options': [20, 40, 80, 100],
                'items-per-page-text': 'на стор.'
            },
            newQuery: {}
        }
    },
    methods: {
        ...mapActions(['actionsList', 'touch', 'searchGoods', 'getActionsFilters', 'getContactInfo']),
        ...mapGetters(['catalogTree']),
        loadList() {
            this.touch()
                .then(() => {
                    this.parseQuerySet()
                    const stats = (Object.keys(this.stats)).filter(el => this.stats[el])
                    const cats = (Object.keys(this.cats)).filter(el => this.cats[el])
                    const ress = (Object.keys(this.ress)).filter(el => this.ress[el])
                    const types = (Object.keys(this.types)).filter(el => this.types[el])

                    this.actionsList({
                        options: this.options,
                        stats,
                        cats,
                        ress,
                        types,
                        actionId: this.actionId,
                        goodId: this.goods,
                    })
                    this.getActionsFilters({
                        options: this.options,
                        stats,
                        cats,
                        ress,
                        types,
                        actionId: this.actionId,
                        goodId: this.goods
                    })
                })
        },
        loadListR(reset) {
            if (reset) {
                this.setPage(1)
            } 
            this.loadList()
        },
        loadGoodsList() {
            this.searchGoods(this.searchGood)
        },
        parseQuerySet() {
            const q = this.$route.query
            const cats = q.cats && q.cats.split(',')
            const ress = q.ress && q.ress.split(',')
            const stats = q.stats && q.stats.split(',')
            const types = q.types && q.types.split(',')
            this.stats = {}
            this.cats = {}
            this.ress = {}
            this.types = {}
            if (cats) {
                for (let id of cats) {
                    this.cats[id] = true
                }
            }
            if (ress) {
                for (let id of ress) {
                    this.ress[id] = true
                }
            }
            if (stats) {
                for (let id of stats) {
                    this.stats[id] = true
                }
            }
            if (types) {
                for (let id of types) {
                    this.types[id] = true
                }
            }
        },
        drawerOpenClose() {
            this.$store.commit('openCloseFiltersDrawer', !this.filtersDrawerOpened);
        },
        setPage(page) {
            const oldPage = this.$route.query['page']
            delete this.$route.query['page']
            Object.assign(this.newQuery, {
                ...this.$route.query,
                page: page > 1 ? page : undefined
            })

            if (oldPage || page != 1) {
                this.setNewQuery()
            }
        },
        setNewQuery() {
            this.$router.push({
                path: '',
                query: this.newQuery
            })
            this.newQuery = {}
        },

    },
    watch: {
        options: {
            handler(n, o) {
                if (n.page != o.page) {
                    this.setPage(n.page)
                }
                this.$vuetify.goTo(0)
            },
            deep: true,
        },
        searchGood(val) {
            val && val !== this.goods && this.loadGoodsList()
        },
        '$route.query': {
            handler(n, o) {
                    if (!n.page) {
                        this.page = 1
                    }
                this.loadListR()
            },
            deep: true,
        }
    },
    created() {
        this.getContactInfo()
            .then(data => {
                if (data.groups && data.groups.cl_corp_action) {
                    this.show = true
                    this.parseQuerySet()
                    // this.loadListR()
                } else {
                    this.$router.push('/403')
                }
            })
            .catch(error => console.error(error))
    },
    mounted() {
        this.options.page = this.page = Number(this.$route.query && this.$route.query.page) || 1
    },
    computed: {
        list() {
            return this.$store.state.actions.list
        },
        totalItems() {
            return this.$store.state.actions.total
        },
        listLoading() {
            return this.$store.state.actions.loading
        },
        goodsItems() {
            return this.$store.state.actions.searchGoods
        },
        filters() {
            return this.$store.state.actions.filters
        },
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
    },
}
</script>

<style>
.v-text-field.v-text-field--solo.v-input--dense>.v-input__control {
    height: 38px;
}
</style>
